<template>
  <div class="bgcard">
    <div class="bgcolors">
      <div class="bgcolor" v-if="devnone">
        <div
        class="equimentheader"
        v-for="(item, index) in allslist"
        :key="index"
      >
      <div class="topnamesad">
        {{item.name}}
      </div>
        <div class="equimentheader_b">
          <div class="devxx_right">
            <div class="devxx_rights">
              <div
                class="devxx_video"
                @click="videos(item.mid, item, item.steamAddr, index)"
                ref="video_a"
              >
                <img src="../../../../assets/imgs/video.png" />
              </div>
              <video
                :id="'video-webrtc' + index"
                muted
                autoplay
                controls
                ref="movie"
              ></video>
            </div>
          </div>
          <!-- 折线图 -->
          <div style="width: 3.6rem">
            <div
              :id="'mainsa' + index"
              style="width: 99%; height: 2.2rem; background-color: #222628"
            ></div>
          </div>
        </div>
        <div class="bntncolors" v-if="item.status">
          <div class="topleftad">
            <img class="imgadsss" src="@/assets/imgs/devlis/VBR.png" />
            {{ $t("lang.codingAlgorithm") }}：{{
              item.deviceConfigDto.encodeType ? "H.265" : "H.264"
            }}
          </div>
          <div class="dianlaing">
            {{ $t("lang.electricitySurplus") }}：
            <div v-if="item.status">
              <div
                class="statemsg"
                v-if="
                  item.battery &&
                  item.deviceStatus &&
                  item.deviceStatus.batteryStatus != 2
                "
              >
                <span
                  class="statemsg_a"
                  :class="
                    item.battery > 0 && item.battery < 25 ? 'statemsg_b' : ''
                  "
                >
                  <div
                    class="state"
                    :style="{ width: item.batterys }"
                    :class="
                      item.battery > 0 && item.battery < 25 ? 'states' : ''
                    "
                  ></div>
                  <img
                    v-if="item.deviceStatus.batteryStatus == 1"
                    src="@/assets/imgs/devlis/chond.png"
                  />
                </span>
                <div
                  class="state_a"
                  :class="
                    item.battery > 0 && item.battery < 25 ? 'state_b' : ''
                  "
                ></div>
                <p style="margin-left: 0.08rem">
                  {{ item.battery ? item.battery : 0 }}%
                </p>
              </div>
              <div
                v-if="item.deviceStatus.batteryStatus == 2"
                style="margin-top: 0.15rem"
              >
                <img src="@/assets/imgs/devlis/notbit.png" />
              </div>
            </div>
            <div v-else class="adlslls">
              <!-- <div class="statemsg">
                  <span class="statemsg_a">
                    <div class="state" :style="{ width: item.batterys }"></div>
                  </span>
                  <div class="state_a"></div>
                </div> -->
                <img src="@/assets/imgs/devlis/notbit.png" />
              <div style="padding-left:0.05rem">0%</div>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 7rem; height: 3rem"></div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import * as admin from "@/api/roleList.js";
// import bus from "@/utils/bus.js";
import { getEqList } from "@/api/apis";
import * as Apis from "@/api/apis";
import * as equipmentApis from "@/api/equipment";
// import batteryCharts from "./components/echarts/battery";
// import progressCharts from "../../../../components/echarts/progress";
// import progressBarCharts from "../../../../components/echarts/progressBar";
import addFurl from "./components/addUrl";
import importPlan from "./components/importPlan";
//import addDevice from "./components/addDevice";
import delDevice from "./components/delDevice";
import setDevice from "./components/setDevice";
import bus from "@/utils/bus.js";
//import { time } from "echarts";
import * as tiemsa from "@/utils/time";
import * as userApi from "@/api/apis";
import { JSWebrtc } from "./components/video/jswebrtc.js";
import { integersa, inttext } from "@/utils/time";
import { json } from "body-parser";
export default {
  components: {
    // batteryCharts,
    // progressCharts,
    // progressBarCharts,
    addFurl,
    importPlan,
    //addDevice,
    delDevice,
    setDevice,
  },
  filters: {
    formatTime(value) {
      //console.log(value)
      let hr = parseInt((value / 60 / 60) % 24);
      let min = parseInt((value / 60) % 60);
      let sec = parseInt(value % 60);

      hr = hr > 9 ? hr : "0" + hr;
      min = min > 9 ? min : "0" + min;
      sec = sec > 9 ? sec : "0" + sec;
      return `${hr}:${min}:${sec}`;
    },
    mids(val) {
      let sk = "";
      if (val) {
        sk = val.slice(16);
      }
      return sk;
    },
  },
  data() {
    return {
      start: true,
      eqlist: [],
      skrAlllist: [],
      apiTimer: null,
      checked: false,
      deviceTimer: null,
      taltimer: null,
      isShows: false,
      batteryTips: this.$t("lang.electricitySurplus"),
      recordTime: [],
      marks: {
        0: "0°C",
        100: "37°C",
      },
      livetime: "00:00:00",
      livetimer: null,
      url: "",
      userxx: {},
      devequipmen: {},
      getdev: null,
      filgs: false,
      date: [],
      dase: [],
      charttimell: null,
      chartimes: null,
      dialogVisible: false,
      titles: "",
      opens: {
        workName: "",
      },
      myChart: "",
      devnone: false,
      videhttp: "",
      default: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      defaults: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      player: "",
      myset: {
        mad: "",
        hcho: "",
      },
      cosnts: 0,
      cosntsa: 0,
      tabekbs: "",
      batterys: "0%",
      cachesa: false,
      cachess: "",
      caches: "",
      bitess: "",
      bitesa: false,
      switchs: false,
      switchdata: {},
      dialogarrars: false,
      monys: 0,
      arrertitle: "",
      balanas: "",
    };
  },
  created() {
    let surl = window.location.href;
    let sker = surl.indexOf("nav");
    let dk = surl.slice(0, sker);
    this.videhttp = "static/indx.html";
    //console.log(dk)
    this.userxx = JSON.parse(sessionStorage.getItem("user"));
    this.getdevlist();
    this.getdev = setInterval(() => {
      this.getdevlist();
    }, 1000);
    // this.getsea();
    // this.getdev = setInterval(() => {
    //   this.getsea();
    // }, 1000);
    bus.$on("langchange", () => {
      this.batteryTips = this.$t("lang.electricitySurplus");
    });
  },
  mounted() {
    this.$once("hook:beforeDestroy", () => {
      clearInterval(this.apiTimer);
    });
    if (!sessionStorage.getItem("getBindPhoneStatus")) {
      this.getBindPhoneStatus();
    }
  },
  methods: {
    //获取设备列表
    async getdevlist() {
      let that = this;
      let tals_a = [];
      let params = {
        email: JSON.parse(sessionStorage.getItem("user")).email,
        type: "0",
      };
      let res = await getEqList(params);
      //console.log(res)
      let equipmentList = {};
      if (res.data.length > 0) {
        that.devnone = true;
        res.data.forEach((item, index) => {
          //   tals_a.push({
          //     tex: item.name,
          //     imd: item.mid,
          //     off: false,
          //     live: false,
          //     recs: false,
          //     onfile: false,
          //   });
          // debugger
          item.adlist = item.steamAddr;
          if (item.steamAddr) {
            let sturl = JSON.parse(item.steamAddr);
            let videodes = [];
            let stulr = sturl.pullStream;
            stulr.forEach((items, indexs) => {
              items.isfor = false;
              if (items.type === 3) {
                videodes.push(items);
                stulr.splice(indexs, 1);
              }
            });
            if (
              item.deviceConfigDto.pullStream &&
              item.deviceConfigDto.pullStream.length > 0
            ) {
              item.deviceConfigDto.pullStream.forEach((itea, inids) => {
                if (itea.enable == 1 && itea.type !== 3) {
                  stulr[inids].isfor = true;
                }
              });
            }
            item.steamAddr = stulr;
            item.videos = videodes;
          }
          item.batterys = "0%";
          if (item.battery != null) {
            item.batterys = item.battery + "%";
          }
          //   if (item.deviceRealtimeInfoDto.pushstreamout == null) {
          //     item.deviceRealtimeInfoDto.pushstreamout = [];
          //   }
          //   if (item.status == 1) {
          //     tals_a[index].onfile = true;
          //   } else {
          //     tals_a[index].off = true;
          //   }
          //   if (
          //     item.deviceRealtimeInfoDto &&
          //     item.deviceRealtimeInfoDto.workModeStatus
          //   ) {
          //     if (
          //       item.deviceRealtimeInfoDto.workModeStatus.workMode == 0 &&
          //       item.deviceRealtimeInfoDto.workModeStatus.workStatus == 1
          //     ) {
          //       tals_a[index].live = true;
          //       tals_a[index].onfile = false;
          //     }
          //     if (
          //       item.deviceRealtimeInfoDto.workModeStatus.workMode == 1 &&
          //       item.deviceRealtimeInfoDto.workModeStatus.workStatus == 1
          //     ) {
          //       tals_a[index].recs = true;
          //       tals_a[index].onfile = false;
          //     }
          //   }

          if (item.deviceConfigDto && item.deviceConfigDto.pullStream) {
            item.deviceConfigDto.pullStream.forEach((ites) => {
              if (ites.type === 3) {
                res.data[index].videos = [ites];
              }
            });
          }
          if (item.linkInfo !== null && item.linkInfo.length !== 0) {
            item.linkInfo.forEach((obj) => {
              if (obj.recvRate) {
                obj.recvRate = obj.recvRate.split(",");
              }
              if (obj.signal > 0) {
                obj.signal = Math.round(obj.signal / 20);
              }
            });
          }
          if (
            item.deviceRealtimeInfoDto &&
            item.deviceRealtimeInfoDto.recvRate != null
          ) {
            let sk = item.deviceRealtimeInfoDto.recvRate.split(",");
            let skd = sk[sk.length - 1];
            item.deviceRealtimeInfoDto.recvRate = (
              (Number(skd) * 8) /
              1024
            ).toFixed(2);
            let saw = [];
            sk.forEach((itesa) => {
              let skr = ((Number(itesa) * 8) / 1024).toFixed(2);
              saw.push(skr);
            });
            item.Linechart = "";
            item.Linechart = saw;
          }
          that.chars(item, index);
        });
        // debugger
        that.allslist = res.data;
        console.log(that.allslist);

        that.drawChart();

        // sessionStorage.setItem("allslist", JSON.stringify(res.data));
        // let skrs = JSON.parse(sessionStorage.getItem("devfilg"));
        // if (skrs) {
        //   if (res.data[skrs]) {
        //     equipmentList = JSON.stringify(res.data[skrs]);
        //   } else {
        //     equipmentList = JSON.stringify(res.data[res.data.length - 1]);
        //   }
        // } else {
        //   equipmentList = JSON.stringify(res.data[0]);
        // }
        // this.resdata = JSON.parse(equipmentList);
        // sessionStorage.setItem("devlist", equipmentList);
      } else {
        this.devnone = false;
        // sessionStorage.setItem("devlist", null);
        // sessionStorage.setItem("allslist", null);
      }
      this.tals = tals_a;
      //console.log(this.tals)
    },
    chars(item, index) {
      let newparosm = new Promise((resolve) => {
        resolve();
      });
      newparosm.then(() => {
        if (item.Linechart) {
          let sk = 0;
          item.Linechart.forEach((item) => {
            sk = sk + parseInt(item);
          });
          item.tabekbs = (sk / 20).toFixed(2) + " kbps";
        } else {
          item.tabekbs = "0 kbps";
        }
        // let ske = setInterval(() => {
        //   if (document.getElementById("mainsa" + index)) {
        //     clearInterval(ske);
        //     this.drawChart(item, index);
        //   }
        // }, 100);
        //   this.dlistChart(item,index);
      });
    },
    //获取设备信息
    getsea() {
      console.log("weww");
      let skr = JSON.parse(sessionStorage.getItem("allslist"));
      if (skr) {
        this.devnone = true;

        //   this.devequipmen = skr;
        skr.forEach((item, index) => {
          if (
            item.deviceRealtimeInfoDto &&
            item.deviceRealtimeInfoDto.workModeStatus &&
            item.deviceConfigDto
          ) {
            if (
              item.deviceRealtimeInfoDto.workModeStatus.workMode === 0 &&
              item.deviceRealtimeInfoDto.workModeStatus.workStatus === 0 &&
              item.deviceConfigDto.recordStatus === 1
            ) {
              this.handleStartRecords(item, 0);
            }
            if (item.deviceConfigDto && item.deviceConfigDto.pullStream) {
              let sewq = false;
              item.deviceConfigDto.pullStream.forEach((item) => {
                if (item.type === 3) {
                  sewq = true;
                }
              });
              // debugger
              if (sewq == false) {
                this.videosa(item);
              }
            }
          }
          //   this.chars(item, index);
        });
        this.skrAlllist = skr;
        this.drawChart();
      } else {
        this.filgs = false;
        this.devnone = false;
      }
    },
    inps(e) {
      if (this.devequipmen.status === 0) {
        return false;
      }
      //console.log(this.cosnts)
      this.cosnts++;
      if (this.cosnts > 2) {
        clearInterval(this.getdev);
      }
    },
    inpsa(e) {
      if (this.devequipmen.status === 0) {
        return false;
      }
      //console.log(this.cosntsa)
      this.cosntsa++;
      if (this.cosntsa > 2) {
        clearInterval(this.getdev);
      }
    },
    //处理预览问题
    async videosa(res) {
      //console.log(res)
      let arrs = [];
      if (res.steamAddr) {
        res.steamAddr.forEach((ibs, index) => {
          arrs.push({
            url: ibs.url,
            type: ibs.type,
            enable: "",
          });
          if (ibs.isfor) {
            arrs[index].enable = 1;
          } else {
            arrs[index].enable = 0;
          }
        });
      }
      if (res.videos.length > 0) {
        arrs.push({
          url: res.videos[0].url,
          type: res.videos[0].type,
          enable: 1,
        });
      }
      let arrs_b = [];
      if (res.deviceConfigDto.streamout) {
        res.deviceConfigDto.streamout.forEach((item, index) => {
          arrs_b.push({
            name: "",
            url: item,
          });
        });
      }
      let email = this.userxx.email;
      let params = {
        deviceMid: res.mid,
        email: email,
        name: "",
        nameUrls: arrs_b,
        pullUrl: arrs,
      };
      //console.log('kekk')
      //let dasa=await userApi.addEqUrl(params)
    },
    //停止播放
    handpausse() {
      this.player.destroy();
      if (this.$refs.video_a) {
        this.$refs.video_a.style.zIndex = "100";
      }
    },
    //开始播放
    handlePlay() {
      this.player.play();
    },
    //播放视频
    videos(mid, res, data, index) {
      //console.log(res.videos[0].url)
      if (res.status === 0) {
        this.$message.error(this.$t("lang.CannotEdit"));
        return false;
      }
      if (res.deviceRealtimeInfoDto.workModeStatus.workMode === 1) {
        this.$message.error(this.$t("lang.Youcanpreview"));
        return false;
      }
      if (
        res.deviceRealtimeInfoDto.workModeStatus.workMode === 0 &&
        res.deviceRealtimeInfoDto.workModeStatus.workStatus == 0
      ) {
        this.$message.error(this.$t("lang.Youcanpreview"));
        return false;
      }
      if (res.deviceConfigDto.encodeType === 1) {
        this.$message.error(this.$t("lang.Codingpreview"));
        return false;
      }

      //this.url=''
      //console.log(this.url)
      let arrs = [];
      data.forEach((ibs, index) => {
        arrs.push({
          url: ibs.url,
          type: ibs.type,
          enable: "",
        });
        if (ibs.isfor) {
          arrs[index].enable = 1;
        } else {
          arrs[index].enable = 0;
        }
      });
      if (res.videos.length > 0) {
        arrs.push({
          url: res.videos[0].url,
          type: res.videos[0].type,
          enable: 1,
        });
      }
      let arrs_b = [];
      res.deviceConfigDto.streamout.forEach((item, index) => {
        arrs_b.push({
          name: "",
          url: item,
        });
      });
      let email = this.userxx.email;
      let params = {
        deviceMid: mid,
        email: email,
        name: "",
        nameUrls: arrs_b,
        pullUrl: arrs,
      };
      //   console.log(JSON.parse(this.devequipmen.adlist));
      //   let arrsad = JSON.parse(this.devequipmen.adlist);
      //   arrsad.pullStream.forEach((ims) => {
      //     if (ims.type == 3) {
      //       this.url = ims.url;
      //     }
      //   });

      setTimeout(() => {
        this.url = res.videos[0].url;
        var video = document.getElementById("video-webrtc" + index);

        this.player = new JSWebrtc.Player(this.url, {
          video: video,
          autoplay: true,
        });
        // debugger
        this.$refs.video_a[index].style.zIndex = "90";
      }, 1000);
      userApi.addEqUrl(params).then((res) => {
        setTimeout(() => {
          this.url = this.devequipmen.videos[0].url;
          var video = document.getElementById("video-webrtc" + index);

          this.player = new JSWebrtc.Player(this.url, {
            video: video,
            autoplay: true,
          });
          this.$refs.video_a[index].style.zIndex = "90";
        }, 1000);
      });
    },
    // // 信号折线图
    // dlistChart() {
    //   // console.log(this.devequipmen.linkInfo)
    //   if (this.devequipmen.linkInfo.length > 0) {
    //     this.devequipmen.linkInfo.forEach((items) => {
    //       items.recvRate.forEach((ims) => {
    //         ims = parseInt(Number(ims) / 1024 / 1024);
    //         // ims = parseInt((Number(ims) * 8) / 1024);
    //       });
    //     });
    //   }
    //   this.$nextTick(() => {
    //     this.devequipmen.linkInfo.forEach((ims, idx) => {
    //       console.log(ims.recvRate);
    //       // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
    //       let myChart = this.$echarts.init(
    //         document.getElementById("echasads" + idx)
    //       );
    //       // 指定图表的配置项和数据
    //       let option = {
    //         xAxis: {
    //           type: "category",
    //           data: ["0:1", "0:4", "0:7", "0:10", "0:13", "0:16", "0:19"],
    //           axisTick: {
    //             alignWithLabel: true,
    //           },
    //         },
    //         yAxis: {
    //           type: "value",
    //           nameTextStyle: {
    //             padding: [0, 0, 0, 5],
    //           },
    //           splitLine: {
    //             show: true,
    //             lineStyle: {
    //               color: ["#B2B2B2"],
    //               width: 1,
    //               type: "solid",
    //             },
    //           },
    //         },
    //         series: [
    //           {
    //             data: ims.recvRate,
    //             type: "line",
    //             // smooth: true,
    //             // showSymbol: false,
    //             itemStyle: {
    //               normal: {
    //                 color: "#B7BAC5", //改变折线点的颜色
    //                 lineStyle: { color: "#B7BAC5" }, //改变折线颜色
    //               },
    //             },
    //           },
    //         ],
    //       };
    //       // 使用刚指定的配置项和数据显示图表。
    //       myChart.setOption(option);
    //     });
    //   });
    // },
    //初始化折线图
    drawChart(item, index) {
      this.$nextTick(() => {
        this.allslist.forEach((ims, idx) => {
          //   console.log(ims.recvRate);

          let thia = this;
          // 基于准备好的dom，初始化echarts实例
          let myChart = thia.$echarts.init(
            document.getElementById("mainsa" + idx)
          );
          let option = {
            tooltip: {
              trigger: "axis",
              // show: false "<div>"+thia.$t("lang.coordinate")+"："+res[0].axisValue+
              formatter: function (res) {
                return (
                  "</div>" +
                  "<div>" +
                  thia.$t("lang.bitRate") +
                  "：" +
                  res[0].data +
                  " kbps" +
                  "</div>"
                );
              },
            },
            title: {
              subtext: ims.tabekbs,
              x: "right",
              padding: [5, 40, 2, 7],
            },
            calculable: true,
            xAxis: [
              {
                type: "category",
                boundaryGap: false,
                axisTick: {
                  show: false,
                },
                show: false,

                //data : thia.default
              },
            ],
            yAxis: [
              {
                type: "value",
                // min:0,
                // max:15360,
                axisTick: {
                  show: false,
                },
                name: "kbps",
                nameTextStyle: {
                  padding: [30, 50, 0, 5],
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    color: ["#B2B2B2"],
                    width: 1,
                    type: "solid",
                  },
                },
              },
            ],
            grid: {
              left: "20px",
              right: "30px",
              bottom: "10px",
              top: "40px",
              containLabel: true,
            },
            series: [
              {
                name: "kbps",
                type: "line",
                smooth: true,
                showSymbol: false,
                itemStyle: {
                  normal: {
                    areaStyle: {
                      type: "default", //渐变色实现===
                      color: new thia.$echarts.graphic.LinearGradient(
                        0,
                        0,
                        0,
                        1,
                        [
                          {
                            offset: 0,
                            color: "#2E7135",
                          },
                          {
                            offset: 1,
                            color: "#000000",
                          },
                        ]
                      ),
                    },
                    lineStyle: {
                      color: "#2E7135",
                    }, //线的颜色
                    label: {
                      show: false,
                      position: "top",
                      textStyle: {
                        color: "#B7BAC5",
                      }, //以及在折线图每个日期点顶端显示数字
                    },
                  },
                },
                stack: "总量",
                data: ims.Linechart ? ims.Linechart : thia.default,
              },
            ],
          };
          // 使用刚指定的配置项和数据显示图表。
          myChart.setOption(option);
        });
      });
    },
    // unique(arr) {
    //   return arr.filter(function (item, index, arr) {
    //     //当前元素，在原始数组中的第一个索引==当前索引值，否则返回当前元素
    //     return arr.indexOf(item.url, 0) === index;
    //   });
    // },
    // //操作
    // refreshDeviceMsg(item) {
    //   this.cosnts = 0;
    //   this.cosntsa = 0;
    //   //console.log(item)
    //   //this.handleRecord(item.recordStatus, item);
    // },
    // /**************页面功能********************/
    // //设置码率
    // changeList(e, item) {
    //   this.cosnts = 0;
    //   this.cosntsa = 0;
    //   //console.log(e)
    //   if (!item.status) {
    //     this.$message({
    //       message: this.$t("lang.CannotEdit"),
    //       type: "error",
    //     });
    //     return;
    //   }
    //   clearInterval(this.getdev);
    //   let params = {
    //     adminEmail: this.userxx.email,
    //     mid: item.mid,
    //     name: item.name,
    //     videoBitrate: e,
    //     cache: item.deviceDefaults.cache,
    //     videoCbr: item.deviceDefaults.videoCbr,
    //     encodeFmt: item.deviceDefaults.encodeFmt,
    //     encodeFps: item.deviceDefaults.encodeFps,
    //     encodeType: item.deviceDefaults.encodeType,
    //     outputPolicy: item.deviceDefaults.outputPolicy,
    //     protocol: item.deviceDefaults.protocol,
    //     recordStatus: item.deviceConfigDto.recordStatus,
    //     workMode: item.deviceDefaults.workMode,
    //   };
    //   equipmentApis.setDevice(params).then((res) => {
    //     if (res.data) {
    //       this.$message.success(this.$t("lang.Operations"));
    //       this.$set(item.deviceDefaults, "videoBitrate", e);
    //       this.getdev = setInterval(() => {
    //         this.getsea();
    //       }, 1000);
    //     }
    //   });
    // },
    // //设置缓冲
    // changeListTwo(e, item) {
    //   this.cosnts = 0;
    //   this.cosntsa = 0;
    //   if (!item.status) {
    //     this.$message({
    //       message: this.$t("lang.CannotEdit"),
    //       type: "error",
    //     });
    //     return;
    //   }
    //   clearInterval(this.getdev);
    //   let params = {
    //     adminEmail: this.userxx.email,
    //     mid: item.mid,
    //     name: item.name,
    //     videoBitrate: item.deviceDefaults.videoBitrate,
    //     cache: e,
    //     videoCbr: item.deviceDefaults.videoCbr,
    //     encodeFmt: item.deviceDefaults.encodeFmt,
    //     encodeFps: item.deviceDefaults.encodeFps,
    //     encodeType: item.deviceDefaults.encodeType,
    //     outputPolicy: item.deviceDefaults.outputPolicy,
    //     protocol: item.deviceDefaults.protocol,
    //     recordStatus: item.deviceConfigDto.recordStatus,
    //     workMode: item.deviceDefaults.workMode,
    //   };
    //   equipmentApis.setDevice(params).then((res) => {
    //     if (res.data) {
    //       this.$message.success(this.$t("lang.Operations"));
    //       this.$set(item.deviceDefaults, "cache", e);
    //       this.getdev = setInterval(() => {
    //         this.getsea();
    //       }, 1000);
    //     }
    //   });
    // },
    // // 点击拉流开关
    // switceChangeOne(e, mid, resa, data, item) {
    //   console.log(item);
    //   if (resa.status === 0) {
    //     this.$message.error(this.$t("lang.CannotEdit"));
    //     return false;
    //   }
    //   let arrs = [];
    //   data.forEach((ibs, index) => {
    //     arrs.push({
    //       url: ibs.url,
    //       type: ibs.type,
    //       enable: "",
    //     });
    //     if (ibs.isfor) {
    //       arrs[index].enable = 1;
    //     } else {
    //       arrs[index].enable = 0;
    //     }
    //   });
    //   let arrs_b = [];
    //   resa.deviceConfigDto.streamout.forEach((item, index) => {
    //     arrs_b.push({
    //       name: "",
    //       url: item,
    //     });
    //   });
    //   let email = this.userxx.email;
    //   let params = {
    //     deviceMid: mid,
    //     email: email,
    //     name: "",
    //     nameUrls: arrs_b,
    //     pullUrl: arrs,
    //   };
    //   userApi.addEqUrl(params).then((res) => {
    //     this.$message({
    //       message: this.$t("lang.Operations"),
    //       type: "success",
    //     });
    //   });
    //   this.$set(item, "isfor", !item.isfor);
    // },
    // // 点击复制
    // fuzhiUrl(urls) {
    //   this.$copyText(urls).then(
    //     (res) => {
    //       this.$message.success(this.$t("lang.Copysucceeded"));
    //     },
    //     (error) => {
    //       this.$message.success(this.$t("lang.copyfailed"));
    //     }
    //   );
    // },
    // //设置
    // handleSetDevice(item) {
    //   if (!item.status) {
    //     this.$message({
    //       message: this.$t("lang.CannotEdit"),
    //       type: "error",
    //     });
    //     return;
    //   }

    //   this.$refs.setDevice.open(item);
    // },
    // //设备详情
    // handleDetail(item) {
    //   this.$router.push({
    //     path: "/nav/user/equipmentmsg",
    //     query: {
    //       mid: item.mid,
    //     },
    //   });
    // },
    // //导入方案
    // handleImportPlan(item, pullstreamout) {
    //   if (!item.status) {
    //     this.$message({
    //       message: this.$t("lang.CannotEdit"),
    //       type: "error",
    //     });
    //     return;
    //   }
    //   this.$refs.importPlan.open(item, pullstreamout);
    // },

    // //添加推流地址
    // addPushName(item, data, steamAddr) {
    //   if (item.status === 0) {
    //     this.$message.error(this.$t("lang.CannotEdit"));
    //     return false;
    //   }
    //   if (data.length < 4) {
    //     this.$refs.addFurl.open(item, data, steamAddr);
    //   } else {
    //     this.$message.error(this.$t("lang.vbs"));
    //   }
    // },
    // //开始、结束录制
    // handleStartRecord(item, index) {
    //   if (
    //     item.deviceRealtimeInfoDto.workModeStatus.workMode === 0 &&
    //     item.deviceRealtimeInfoDto.workModeStatus.workStatus == 0
    //   ) {
    //     this.$message.error(this.$t("lang.canonlyberecorded"));
    //     return false;
    //   }
    //   let params = {
    //     adminEmail: this.userxx.email,
    //     mid: item.mid,
    //     name: item.name,
    //     videoBitrate: item.deviceDefaults.videoBitrate,
    //     cache: item.deviceDefaults.cache,
    //     videoCbr: item.deviceDefaults.videoCbr,
    //     encodeFmt: item.deviceDefaults.encodeFmt,
    //     encodeFps: item.deviceDefaults.encodeFps,
    //     encodeType: item.deviceDefaults.encodeType,
    //     outputPolicy: item.deviceDefaults.outputPolicy,
    //     protocol: item.deviceDefaults.protocol,
    //     recordStatus: index,
    //     workMode: item.deviceDefaults.workMode,
    //   };
    //   equipmentApis.setDevice(params).then((res) => {
    //     if (res.data) {
    //       this.$message.success(this.$t("lang.Operations"));
    //     }
    //   });
    // },
    // //结束收录
    // handleStartRecords(item, index) {
    //   let params = {
    //     adminEmail: this.userxx.email,
    //     mid: item.mid,
    //     name: item.name,
    //     videoBitrate: item.deviceDefaults.videoBitrate,
    //     cache: item.deviceDefaults.cache,
    //     videoCbr: item.deviceDefaults.videoCbr,
    //     encodeFmt: item.deviceDefaults.encodeFmt,
    //     encodeFps: item.deviceDefaults.encodeFps,
    //     encodeType: item.deviceDefaults.encodeType,
    //     outputPolicy: item.deviceDefaults.outputPolicy,
    //     protocol: item.deviceDefaults.protocol,
    //     recordStatus: index,
    //     workMode: item.deviceDefaults.workMode,
    //   };
    //   equipmentApis.setDevice(params).then((res) => {
    //     if (res.data) {
    //       //console.log('kk')
    //     }
    //   });
    // },
    // // 取消绑定手机弹框
    // clickRemove() {
    //   this.isShows = false;
    // },
    // // 绑定手机
    // clickOk() {
    //   this.$router.push({
    //     path: "/nav/personal",
    //     query: { ind: 3 },
    //   });
    //   this.isShows = false;
    // },
    // changeClick(e) {
    //   this.getBindPhoneStatus(e);
    // },
    // // 验证是否绑定手机
    // getBindPhoneStatus(checked) {
    //   let remind = "";
    //   if (checked) {
    //     remind = 1;
    //   } else if (checked === false) {
    //     remind = 0;
    //   }
    //   let email = this.userxx.email;
    //   let data = {
    //     email: email,
    //     remind: remind,
    //   };

    //   Apis.getBindPhoneStatus(data).then((res) => {
    //     if (res.data.remind == 1) {
    //       //this.isShows = false;
    //       sessionStorage.setItem("getBindPhoneStatus", 1);
    //     }
    //     if (res.data.remind == 0 && res.data.isBindPhone == 0) {
    //       this.isShows = true;
    //       sessionStorage.setItem("getBindPhoneStatus", 1);
    //     }
    //     if (res.data.remind == 0 && res.data.isBindPhone == 1) {
    //       //this.isShows = false;
    //     }
    //   });
    // },
    // //删除推流地址
    // handleDelUrl(items, data, index, pullstreamout) {
    //   if (items.status === 0) {
    //     this.$message.error(this.$t("lang.CannotEdit"));
    //     return false;
    //   }
    //   let pullstreamoutARR = [];
    //   pullstreamout.forEach((ibs, indexs) => {
    //     pullstreamoutARR.push({
    //       url: ibs.url,
    //       type: ibs.type,
    //       enable: "",
    //     });
    //     if (ibs.isfor) {
    //       pullstreamoutARR[indexs].enable = 1;
    //     } else {
    //       pullstreamoutARR[indexs].enable = 0;
    //     }
    //   });
    //   this.$confirm(
    //     this.$t("lang.ThisActionPermanentlyDeletesTheAddress") +
    //       this.$t("lang.WhetherOrNotToContinue"),
    //     this.$t("lang.Tips"),
    //     {
    //       confirmButtonText: this.$t("lang.determine"),
    //       cancelButtonText: this.$t("lang.cancel"),
    //       type: "warning",
    //     }
    //   )
    //     .then(() => {
    //       let email = this.userxx.email;
    //       let soe = JSON.parse(JSON.stringify(data));
    //       soe.splice(index, 1);
    //       let sod = [];
    //       soe.forEach((item) => {
    //         sod.push({
    //           name: "",
    //           url: item.url,
    //         });
    //       });
    //       let params_a = {
    //         deviceMid: items.mid,
    //         email: email,
    //         name: "",
    //         nameUrls: sod,
    //         pullUrl: pullstreamoutARR,
    //       };
    //       Apis.addEqUrl(params_a).then(() => {
    //         this.$message({
    //           message: this.$t("lang.deletessuccess"),
    //           type: "success",
    //         });
    //       });
    //     })
    //     .catch(() => {
    //       this.$message({
    //         type: "info",
    //         message: this.$t("lang.CanceledDelete"),
    //       });
    //     });
    // },
    // //开启关闭直播、录播
    // liveclik(row) {
    //   console.log(row);
    //   if (row.status === 0) {
    //     this.$message.error(this.$t("lang.CannotEdit"));
    //     return false;
    //   }
    //   //console.log(row.deviceRealtimeInfoDto.workModeStatus.workStatus)
    //   this.opens.mid = row.mid;
    //   this.opens.workMode = row.deviceRealtimeInfoDto.workModeStatus.workMode;
    //   if (row.deviceRealtimeInfoDto.workModeStatus.workStatus == 0) {
    //     this.balanas = row.balance;
    //     if (row.balance == 0 || row.balance < 0) {
    //       this.dialogarrars = true;
    //       this.monys = 3;
    //       this.arrertitle = this.$t("lang.Rechargereminder");
    //       return false;
    //     }
    //     this.opens.workStatus = 1;
    //     let tise = new Date().getTime();
    //     this.opens.workName = tiemsa.getTime(tise).setwa;
    //     if (row.deviceRealtimeInfoDto.workModeStatus.workMode == 0) {
    //       this.titles = this.$t("lang.Turnonlive");
    //     } else {
    //       this.titles = this.$t("lang.Startrecordings");
    //     }
    //     this.switchs = false;
    //     this.dialogVisible = true;
    //   }
    //   if (row.deviceRealtimeInfoDto.workModeStatus.workStatus == 1) {
    //     this.switchdata = row;
    //     if (row.deviceRealtimeInfoDto.workModeStatus.workMode == 0) {
    //       this.titles = this.$t("lang.Turnonlives");
    //     } else {
    //       this.titles = this.$t("lang.Startrecordingsa");
    //     }
    //     this.switchs = true;
    //     this.dialogVisible = true;
    //   }
    // },
    // async submitForm(ind) {
    //   if (ind === 0) {
    //     this.opens.workStatus = 0;
    //     this.opens.workName = "";
    //     if (this.switchdata.deviceConfigDto.recordStatus == 1) {
    //       this.handleStartRecord(this.switchdata, 0);
    //     }
    //     if (this.player) {
    //       this.handpausse();
    //     }
    //     this.$refs.video_a.style.zIndex = "100";
    //   }
    //   if (ind !== 0 && this.opens.workName == "") {
    //     this.$message.error(this.$t("lang.Pleaseenteraname"));
    //     return false;
    //   }
    //   if (ind !== 0 && !inttext(this.opens.workName)) {
    //     this.$message.error(this.$t("lang.Onlyentered"));
    //     return false;
    //   }
    //   let res = await Apis.openzb(this.opens);
    //   //console.log(res)
    //   if (res.data) {
    //     this.$message.success(this.$t("lang.Operations"));
    //     this.dialogVisible = false;
    //   }
    // },
    //     //时间处理
    //     shownum(num) {
    //       if (num < 10) {
    //         return "0" + num;
    //       }
    //       return num;
    //     },
    //     //点击缓冲更改
    //     cars(res) {
    //       if (!res.status) {
    //         this.$message({
    //           message: this.$t("lang.CannotEdit"),
    //           type: "error",
    //         });
    //         return;
    //       }
    //       this.caches = res;
    //       this.cachess = res.deviceConfigDto.cache;
    //       this.cachesa = true;
    //       this.cosnts = 0;
    //       this.cosntsa = 0;
    //       setTimeout(() => {
    //         this.$refs.carinput.focus();
    //       }, 100);
    //       clearInterval(this.getdev);
    //     },
    //     //缓冲失去焦点
    //     cachbl() {
    //       if (
    //         integersa(this.cachess) &&
    //         this.cachess > 0 &&
    //         this.cachess <= 30000
    //       ) {
    //         this.updast(this.caches, this.cachess);
    //       } else {
    //         this.$message.error(this.$t("lang.Pleaseenters"));
    //         this.getdev = setInterval(() => {
    //           this.getsea();
    //         }, 1000);
    //         setTimeout(() => {
    //           this.cachesa = false;
    //         }, 1000);
    //       }
    //     },
    //     cachbls() {
    //       this.$refs.carinput.blur();
    //     },
    //     //点击码率更改
    //     bits(res) {
    //       if (!res.status) {
    //         this.$message({
    //           message: this.$t("lang.CannotEdit"),
    //           type: "error",
    //         });
    //         return;
    //       }
    //       this.caches = res;
    //       this.bitess = res.deviceConfigDto.videoBitrate;
    //       this.bitesa = true;
    //       this.cosnts = 0;
    //       this.cosntsa = 0;
    //       setTimeout(() => {
    //         this.$refs.bitinput.focus();
    //       }, 100);
    //       clearInterval(this.getdev);
    //     },
    //     //码率失去焦点
    //     bitbl() {
    //       if (
    //         integersa(this.bitess) &&
    //         this.bitess >= 100 &&
    //         this.bitess <= 15360
    //       ) {
    //         this.updasts(this.caches, this.bitess);
    //       } else {
    //         this.$message.error(this.$t("lang.Pleaseentersa"));
    //         this.getdev = setInterval(() => {
    //           this.getsea();
    //         }, 1000);
    //         setTimeout(() => {
    //           this.bitesa = false;
    //         }, 1000);
    //       }
    //     },
    //     bitbls() {
    //       this.$refs.bitinput.blur();
    //     },
    //     //更新缓冲
    //     updast(item, e) {
    //       let params = {
    //         adminEmail: this.userxx.email,
    //         mid: item.mid,
    //         name: item.name,
    //         videoBitrate: item.deviceDefaults.videoBitrate,
    //         cache: e,
    //         videoCbr: item.deviceDefaults.videoCbr,
    //         encodeFmt: item.deviceDefaults.encodeFmt,
    //         encodeFps: item.deviceDefaults.encodeFps,
    //         encodeType: item.deviceDefaults.encodeType,
    //         outputPolicy: item.deviceDefaults.outputPolicy,
    //         protocol: item.deviceDefaults.protocol,
    //         recordStatus: item.deviceConfigDto.recordStatus,
    //         workMode: item.deviceDefaults.workMode,
    //       };
    //       setTimeout(() => {
    //         this.cachesa = false;
    //       }, 1500);
    //       equipmentApis.setDevice(params).then((res) => {
    //         if (res.data) {
    //           this.$message.success(this.$t("lang.Operations"));
    //           this.$set(item.deviceDefaults, "cache", e);
    //           this.getsea();
    //           this.getdev = setInterval(() => {
    //             this.getsea();
    //           }, 1000);
    //         }
    //       });
    //     },
    //     //更新码率
    //     updasts(item, e) {
    //       let params = {
    //         adminEmail: this.userxx.email,
    //         mid: item.mid,
    //         name: item.name,
    //         videoBitrate: e,
    //         cache: item.deviceDefaults.cache,
    //         videoCbr: item.deviceDefaults.videoCbr,
    //         encodeFmt: item.deviceDefaults.encodeFmt,
    //         encodeFps: item.deviceDefaults.encodeFps,
    //         encodeType: item.deviceDefaults.encodeType,
    //         outputPolicy: item.deviceDefaults.outputPolicy,
    //         protocol: item.deviceDefaults.protocol,
    //         recordStatus: item.deviceConfigDto.recordStatus,
    //         workMode: item.deviceDefaults.workMode,
    //       };
    //       setTimeout(() => {
    //         this.bitesa = false;
    //       }, 1500);
    //       equipmentApis.setDevice(params).then((res) => {
    //         if (res.data) {
    //           this.$message.success(this.$t("lang.Operations"));
    //           this.$set(item.deviceDefaults, "videoBitrate", e);
    //           this.getsea();
    //           this.getdev = setInterval(() => {
    //             this.getsea();
    //           }, 1000);
    //         }
    //       });
    //     },
    //     //去充值
    //     toRecharge() {
    //       this.$router.push({
    //         path: "/nav/personal",
    //         query: { ind: 5 },
    //       });
    //     },
  },
  beforeDestroy() {
    //console.log(this.player.paused)
    if (this.player.paused == false) {
      this.handpausse();
    }
    if (this.getdev) {
      clearInterval(this.getdev);
    }
    if (this.charttimell) {
      clearInterval(this.charttimell);
    }
    if (this.chartimes) {
      clearInterval(this.chartimes);
    }
  },
};
</script>
  
  <style lang="less" scoped>
/deep/.el-checkbox__label {
  color: #438749 !important;
}

/deep/.el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #438749 !important;
}

/deep/.is-checked,
.el-checkbox__inner {
  border-color: #438749 !important;
}

/deep/.el-checkbox__inner:hover {
  border-color: #438749 !important;
}

/deep/.el-slider__bar {
  height: 0.15rem !important;
  border-color: #438749 !important;
  background-color: #438749 !important;
}

/deep/.el-slider__runway {
  height: 0.15rem !important;
}

/deep/.el-slider__button-wrapper {
  height: 0.15rem !important;
  top: -0.05rem;
}

/deep/.el-slider__button {
  border-color: #6b7c6d !important;
}

/deep/.el-slider__stop {
  height: 0.15rem !important;
  width: 0.18rem !important;
}

/deep/.el-slider__marks-text {
  margin-top: 0.22rem !important;
}

/deep/.el-slider__button {
  width: 0.18rem !important;
  height: 0.18rem !important;
}

.bgcard {
  width: 100%;
  padding: 0.19rem 0.17rem;
  box-sizing: border-box;
  border-radius: 0.02rem;
  background-color: rgba(034, 038, 040, 0.76);
  
}
.bgcolors{
  background: #0f1316;
  border-radius: 0.02rem;
  padding: 0.1rem 0.19rem 0.15rem 0.38rem;
  box-sizing: border-box;
  min-height: 9rem;
}
.bgcolor {
  // min-height: calc(100vh - 1.7rem);
  
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.equimentheader {
  // display: flex;
  // justify-content: space-between;
  // padding-top: 0.1rem;
  // padding-bottom: 0.1rem;
  box-sizing: border-box;
  background: #222628;
  margin-bottom: 0.2rem;
  .topnamesad{
    padding: 0.1rem 0.1rem 0;
    color: #b2b2b2;
  }
  .bntncolors {
    margin-bottom: 0.1rem;
    border-top: 1px solid #333333;
    padding: 0.1rem 0.1rem 0;
    color: #b2b2b2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .topleftad{
      display: flex;
    align-items: center;
    }
    .dianlaing {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .adlslls {
        display: flex;
        align-items: center;
      }
    }
    .imgadsss {
      width: 0.23rem;
      margin-right: 0.08rem;
    }
    .statemsg {
      margin-right: 0.1rem;
      display: flex;
      align-items: center;

      p {
        font-size: 0.15rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #b2b2b2;
      }

      .statemsg_a {
        display: inline-block;
        width: 0.34rem;
        height: 0.17rem;
        border: 0.02rem solid #307b37;
        border-radius: 0.03rem;
        padding: 0.01rem;
        position: relative;

        .state {
          background-color: #307b37;
          height: 100%;
        }

        .states {
          background-color: #d70000;
        }

        img {
          position: absolute;
          top: 0.03rem;
          right: 50%;
          display: block;
          width: 0.1rem;
          transform: translateX(50%);
        }
      }
      .statemsg_b {
        border-color: #d70000;
      }

      .state_a {
        width: 0.02rem;
        height: 0.1rem;
        background-color: #307b37;
      }
      .state_b {
        background-color: #d70000;
      }
    }
  }

  .equimentheader_b {
    display: flex;
    //   flex-flow: column;
    // align-items: center;

    .devxx_right {
      width: 3.6rem;
      height: 2.3rem;
      box-sizing: border-box;
      background-color: #222628;
      border-radius: 2px;
      display: flex;
      flex-flow: row;
      justify-content: center;
      align-items: center;
      padding-left: 0.1rem;
      .devxx_rights {
        width: 3.6rem;
        height: 2.02rem;
        position: relative;
        overflow: hidden;

        .devxx_video {
          position: absolute;
          width: 3.6rem;
          height: 2.4rem;
          background-image: url(../../../../assets/imgs/devlis/videosa.png);
          background-size: 100% 100%;
          display: flex;
          flex-flow: row;
          z-index: 100;
          justify-content: center;
          align-items: center;

          img {
            width: 0.5rem;
            height: 0.5rem;
          }
        }

        video {
          left: 0;
          position: absolute;
          z-index: 99;
          background-color: black;
          width: 3.6rem;
          height: 2.4rem;
        }
      }
    }
  }

  .imgcard {
    width: 1rem;
    height: 1rem;
    box-sizing: border-box;
    margin-right: 0.16rem;
    border-radius: 8px;
    position: relative;

    .imgcard_a {
      width: 100%;
      height: 100%;
      display: inline-block;
    }

    .displayimg {
      position: absolute;
      width: 0.16rem;
      height: 0.16rem;
      border-radius: 50%;
      right: -0.06rem;
      bottom: -0.03rem;
    }
  }

  .eqleft {
    display: flex;
    align-items: center;
    margin-left: 3px;
  }

  .eqcontent {
    .deviceName {
      font-size: 0.2rem;
      color: #b2b2b2;
      font-family: Microsoft YaHei;
      font-weight: 400;
      padding-bottom: 0.1rem;
      width: 3.6rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    p {
      margin-bottom: 0.12rem;

      &:first-of-type {
        font-size: 0.18rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #b2b2b2;
      }

      &:nth-of-type(2) {
        padding-left: 1px;
        font-size: 0.18rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #b2b2b2;
      }

      &:last-of-type {
        font-size: 0.16rem;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #b2b2b2;
        margin: 0px;
      }
    }
  }

  .startlive {
    width: 1.52rem;
    height: 0.71rem;
    background: #222628;
    border: 3px solid #59595c;
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
    border-radius: 4px;
    margin-right: 0.48rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    color: #b7b7b7;
    font-size: 0.14rem;
    cursor: pointer;
  }

  .startlives {
    width: 1.52rem;
    height: 0.71rem;
    background: #222628;
    border: 3px solid #cf142f;
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
    border-radius: 4px;
    margin-right: 0.48rem;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    color: #cf142f;
    font-size: 0.14rem;
    cursor: pointer;

    img {
      margin-right: 0.08rem;
      width: 0.32rem;
      height: 0.25rem;
    }
  }

  .startactive {
    background-color: #9f0000;
    color: #fff;
    border: 3px solid #9f0000;
  }
}

.eqcenter {
  height: 93px;
  display: flex;
  align-items: flex-end;
  cursor: default;

  > p {
    font-size: 0.36rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: #b2b2b2;
    margin-right: 0.8rem;
    margin-top: 0.76rem;
  }

  .recording {
    cursor: pointer;
    // width: 152px;
    height: 0.59rem;
    padding: 0 0.1rem;
    background: #307b37;
    border-radius: 0.1rem;
    display: flex;
    // justify-content: start;
    align-items: center;
    margin-top: 0.53rem;

    img {
      // margin: 13px 6px 12px 10px;
      margin-right: 0.05rem;
      width: 0.43rem;
      height: 0.34rem;
    }

    .tips {
      font-size: 0.2rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 0.35rem;
      text-align: center;
    }

    p {
      font-size: 0.2rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 0.35rem;
    }
  }

  .endrec {
    margin-top: 0.53rem;

    button {
      width: 1.52rem;
      height: 0.59rem;
      outline-style: none;
      border: none;
      background: #9f0000;
      border-radius: 0.1rem;

      p {
        color: #ffffff;

        &:first-of-type {
          font-size: 0.2rem;
          font-family: PingFang SC;
          font-weight: bold;
        }

        &:last-of-type {
          font-size: 0.16rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
  }
}

.funt {
  margin-right: 0.1rem;
  display: flex;
  align-items: center;

  .funts {
    display: inline-block;
    height: 0.18rem;
    margin: 0 0.1rem;
    width: 2px;
    background-color: #5293c5;
  }

  img {
    height: 0.26rem;
  }
}

.line {
  /* 线 */
  // width: 1421px;
  height: 1px;
  background: #797979;
  opacity: 0.28;
  margin-bottom: 0.22rem;
}

/* 设备信息 */
.eqmsg {
  display: flex;

  .msgleft {
    width: 3rem;

    .msgrow {
      display: flex;
      align-items: center;
      margin-bottom: 0.16rem;

      p {
        font-size: 0.18rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #b2b2b2;
        line-height: 0.35rem;
      }
    }

    .msgone {
      margin-left: 0.1rem;

      img {
        width: 0.23rem;
        height: 0.16rem;
        margin-right: 0.08rem;
      }
    }

    .msgtwo {
      margin-left: 0.1rem;

      img {
        width: 0.21rem;
        height: 0.19rem;
        margin-right: 0.1rem;
      }
    }

    .msgthree {
      margin-left: 0.1rem;

      img {
        width: 0.22rem;
        height: 0.19rem;
        margin-right: 0.09rem;
      }
    }
  }

  .msgcenter {
    margin-top: 3px;
    margin-left: 0.1rem;

    .eleamount {
      display: flex;
      align-items: center;

      // margin-bottom: .5rem;
      img {
        width: 0.37rem;
        height: 0.2rem;
        margin-right: 0.08rem;
      }

      p {
        &:first-of-type {
          font-size: 0.18rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: #b2b2b2;
          line-height: 0.35rem;
          margin-right: 0.25rem;
        }

        &:last-of-type {
          font-size: 0.14rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #b2b2b2;
          line-height: 0.35rem;
          margin-left: 0.08rem;
        }
      }
    }

    .diskspace {
      // width: 380px;
      display: flex;
      align-items: center;

      p {
        font-size: 0.18rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #b2b2b2;
        line-height: 0.35rem;
        margin-right: 0.23rem;
      }

      .available {
        width: 1.33rem;
        height: 0.13rem;
        background: #307b37;
      }

      .common {
        width: 1.27rem;
        height: 0.13rem;
        background: #dbdbdb;
      }
    }

    .room {
      display: flex;
      justify-content: space-between;
      // margin-left: 100px;
      color: #b2b2b2;
      font-size: 0.16rem;

      p {
        font-size: 0.14rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #b2b2b2;
        line-height: 0.35rem;

        &:first-of-type {
          margin-right: 1.51rem;
        }
      }
    }
  }

  .msgright {
    display: flex;
    margin-left: 0.5rem;

    .speed {
      margin-top: 0.1rem;

      &:first-of-type {
        margin-right: 0.34rem;
      }

      > p {
        font-size: 0.2rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #b2b2b2;
        margin-bottom: 0.2rem;
      }

      .speedtime {
        display: flex;
        justify-content: space-between;
        font-size: 0.16rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #b2b2b2;
        // margin-top: 2px;
      }

      .progressbar {
        /* 进度条 */
        display: flex;
        align-items: center;

        .radioleft {
          width: 1.38rem;
          height: 0.13rem;
          background: #307b37;
          border-radius: 6px;
          margin-right: -0.11rem;
        }

        .posradio {
          width: 0.22rem;
          height: 0.22rem;
          background: #ffffff;
          border-radius: 50%;
          z-index: 1;
        }

        .radioright {
          width: 1.41rem;
          height: 0.13rem;
          background: #dbdbdb;
          border-radius: 0.06rem;
          margin-left: -0.11rem;
        }
      }
    }
  }
}

/deep/.cardtap {
  display: inline-block;
  padding: 0 0.14rem;
  height: 0.25rem;
  line-height: 0.25rem;
  box-sizing: border-box;
  background: #dbdbdb;
  border-radius: 0.4rem;

  .el-input {
    height: 0.2rem;
    display: block;

    input {
      height: 0.2rem;
      border: none;
      padding: 0 0.1rem;
      width: 1rem;
    }
  }
}

.url {
  width: 55%;
  display: flex;
  align-items: center;
  margin-left: 0.08rem;

  p {
    // width: 108px;
    font-size: 0.2rem;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #b2b2b2;
    margin-right: 0.14rem;
  }

  .lines {
    // width: 100%;
    flex: 1;
    height: 1px;
    background: #797979;
    opacity: 0.28;
  }

  button {
    // width: 94px;
    height: 0.37rem;
    padding: 0 5px;
    outline-style: none;
    border: none;
    background: #307b37;
    border-radius: 4px;
    font-size: 0.16rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #dbdbdb;
    margin-right: 0.21rem;
  }
}

/* 模态框 */
.setmsg {
  display: flex;

  .setmsgleft {
    margin-right: 0.3rem;
  }

  select {
    width: 2rem;
    height: 0.42rem;
    border: none;
    outline-style: none;
    background: #f8f8f8;
    border: 1px solid #d5d5d5;
    border-radius: 2px;
  }

  .listmsg {
    display: flex;
    align-items: center;
    margin-bottom: 0.15rem;

    p {
      width: 0.69rem;
      height: 0.16rem;
      font-size: 0.16rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      margin-right: 0.11rem;
    }

    input {
      width: 2rem;
      height: 0.42rem;
      background: #f8f8f8;
      border: 1px solid #d5d5d5;
      border-radius: 2px;
      outline: none;
      text-indent: 0.2rem;
    }
  }

  .setmsgright {
    .listmsg {
      span {
        margin-left: 0.09rem;
      }

      &:nth-of-type(2) {
        input {
          width: 1.68rem;
        }
      }

      &:nth-of-type(3) {
        input {
          width: 1.68rem;
        }
      }

      &:nth-of-type(4) {
        input {
          width: 1.68rem;
        }
      }
    }
  }
}

.addequipment {
  height: 2.4rem;
  background: linear-gradient(0deg, #0b1114, #0b0f12);
  opacity: 0.84;
  display: flex;
  align-items: center;
  justify-content: center;

  .msgeqipment {
    width: 4.7rem;
    height: 1.5rem;
    border: 1px dashed #ababab;
    border-radius: 0.1rem;

    p {
      text-align: center;
      line-height: 1.5rem;

      span {
        font-size: 0.36rem;
        display: inline-block;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #a1a1a1;

        &:first-of-type {
          margin-right: 0.23rem;
        }
      }
    }
  }
}

.choiceplan {
  margin-top: 0.21rem;
  display: flex;
  flex-direction: column;
}

.speed-box {
  display: flex;
  align-items: center;
  color: #b2b2b2;
  font-size: 0.18rem;

  .speed-val {
    width: 0.74rem;
    height: 0.25rem;
    line-height: 0.25rem;
    text-align: center;
    font-size: 14px;
    margin-left: 0.5rem;
    background-color: #dbdbdb;
    color: #797979;
    border-radius: 2px;
  }
}

/deep/.el-radio {
  //从这里开始 就是更改颜色的代码，将你的颜色           改掉我的颜色就可以了
  .el-radio__label {
    color: #000 !important;
  }

  .el-radio__input {
    .el-radio__inner {
      border-radius: 0;
      background-color: #fff;
      border-color: #ccc;
    }
  }

  .el-radio__input.is-checked .el-radio__inner::after {
    content: "";
    width: 0.08rem;
    height: 0.03rem;
    border: 2px solid #333;
    border-top: transparent;
    border-right: transparent;
    text-align: center;
    display: block;
    position: absolute;
    top: 2px;
    left: 1px;
    vertical-align: middle;
    transform: rotate(-45deg);
    border-radius: 0px;
    background: none;
  }
}

// .bitrate-item {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   color: #307b37;
// }
.orangeColor {
  color: #307b37 !important;
  height: 100%;
}

.modals {
  // display: flex;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .modals_posi {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    .modals_center {
      width: 4.2rem;
      // height: 4.15rem;
      background: #fff;
      // position: absolute;
      // top: 0;
      // left: 0;
      // right: 0;
      // bottom: 0;
      // margin: auto;

      .modals_top {
        padding: 0.18rem 0;
        background: #dddddd;
        text-align: center;
        font-size: 0.24rem;
      }

      .modals_img {
        width: 0.53rem;
        height: 0.87rem;
        margin: 0.27rem auto;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .modals_text {
        padding: 0 0.6rem;
        font-size: 0.15rem;
        color: #333333;
        text-align: center;
      }

      .checkboxs {
        text-align: center;
        padding: 0.15rem 0;
      }

      .modals_bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0.15rem;
        padding-bottom: 15px;
        box-sizing: border-box;

        .allcl {
          width: 0.79rem;
          height: 0.4rem;
          border-radius: 0.3rem;
          text-align: center;
          line-height: 0.4rem;
          font-size: 0.15rem;
        }

        .modals_bottom_remove {
          background: #fff;
          border: 0.02rem solid #438749;
          color: #438749;
        }

        .modals_bottom_op {
          background: #438749;
          color: #ededed;
          margin-left: 0.45rem;
        }
      }
    }
  }
}

.xinhao1 {
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
}

.xinhao {
  display: flex;
  flex-flow: row;
  align-items: flex-end;
  position: relative;
  top: -0.03rem;

  div:nth-child(1) {
    width: 2px;
    height: 4px;
    background-color: #307b37;
    border-radius: 2px;
    margin-right: 2px;
  }

  div:nth-child(2) {
    width: 2px;
    height: 8px;
    background-color: #307b37;
    border-radius: 2px;
    margin-right: 2px;
  }

  div:nth-child(3) {
    width: 2px;
    height: 12px;
    background-color: #307b37;
    border-radius: 2px;
    margin-right: 2px;
  }

  div:nth-child(4) {
    width: 2px;
    height: 16px;
    background-color: #307b37;
    border-radius: 2px;
  }
}

.switchs {
  display: flex;
  align-items: center;
  padding: 20px 0;

  .switce_ad {
    display: flex;
    align-items: center;
    margin-right: 30px;

    .titles {
      padding: 0 10px;
      color: #b2b2b2;
    }

    .fizhi {
      color: #307b37;
      cursor: pointer;
    }
  }
}

.urlright {
  margin-top: 0.1rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .bitmsg {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    width: 5rem;
    // height: 2.2rem;
    background: #222628;
    border-radius: 0.1rem;
    padding: 0.12rem 0.08rem;
    margin-bottom: 0.1rem;
    margin-right: 0.1rem;

    .bitrate-item {
      display: flex;
      flex-flow: row;
      // justify-content: space-between;
      justify-content: flex-start;
      align-items: center;
    }
  }
}

.lubo {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  img {
    width: 22px !important;
    height: 20px !important;
  }
}

.zhibo {
  color: #b2b2b2;
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-right: 60px;

  div {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  img {
    width: 22px;
    height: 18px;
    margin-right: 5px;
  }
}

/deep/.devopenzb {
  .el-dialog__header {
    text-align: center;
    background-color: #dddddd;
    padding: 0;
    height: 0.59rem;
    line-height: 0.59rem;

    .el-dialog__headerbtn {
      display: none;
    }
  }

  .devzb {
    display: flex;
    align-items: center;
  }

  .dialog-footer {
    display: inline-block;
    margin-top: 0.3rem;
    display: flex;
    flex-flow: row;
    justify-content: center;

    button {
      width: 0.92rem;
      height: 0.46rem;
      background-color: #fff;
      border: 1px solid #438749;
      border-radius: 0.1rem;
      margin-right: 0.53rem;
      color: #438749;
    }

    button:nth-child(2) {
      background-color: #438749;
      border-radius: 0.1rem;
      color: #fff;
    }
  }
}

.zwdev {
  background-color: #0f1316;
  color: #b2b2b2;
  text-align: center;
  height: 0.5rem;
  line-height: 0.5rem;
}
/deep/ .arrears {
  .el-dialog__header {
    text-align: center;
    background-color: #dddddd;
    padding: 0;
    height: 0.5rem;
    line-height: 0.5rem;
    .el-dialog__headerbtn {
      display: none;
    }
  }
  .tipse {
    div {
      color: #333333;
      text-align: center;
      font-size: 0.16rem;
      line-height: 0.35rem;
    }
  }
  .dialog-footer {
    display: inline-block;
    margin-top: 0.3rem;
    display: flex;
    flex-flow: row;
    justify-content: center;

    button {
      width: 0.92rem;
      height: 0.46rem;
      background-color: #fff;
      border: 1px solid #438749;
      border-radius: 0.1rem;
      margin-right: 0.32rem;
      color: #438749;
      font-size: 0.16rem;
    }

    button:nth-child(2) {
      background-color: #438749;
      border-radius: 0.1rem;
      color: #fff;
      margin-right: 0;
    }
  }
}
</style>
  